import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: 'produktet', loadChildren: () => import('./produkt/produkt.module').then(m => m.ProduktModule) },
    { path: 'portofol', loadChildren: () => import('./portofol/portofol.module').then(m => m.PortofolModule) },
    { path: 'faqe', loadChildren: () => import('./faqe/faqe.module').then(m => m.FaqeModule) },
    { path: 'kontakt', loadChildren: () => import('./kontakt/kontakt.module').then(m => m.KontaktModule) },
    { path: 'punesim', loadChildren: () => import('./punesim/punesim.module').then(m => m.PunesimModule) },
    { path: 'funksionalitete', loadChildren: () => import('./sherbime/sherbime.module').then(m => m.SherbimeModule) },
    { path: 'njoftime', loadChildren: () => import('./njoftime/njoftime.module').then(m => m.NjoftimeModule) },
    { path: 'newsletter', loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule) },
    { path: 'subjekt', loadChildren: () => import('./subjekt/subjekt.module').then(m => m.SubjektModule) },
    { path: 'subjektLogin', loadChildren: () => import('./subjekt-login/subjekt-login.module').then(m => m.SubjektLoginModule) },
    { path: 'kliente', loadChildren: () => import('./klient/klient.module').then(m => m.KlientModule) }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
