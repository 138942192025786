import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AppService {

    url = environment.apiUrl;
    isOpen = true;
    // tslint:disable-next-line: no-output-native
    @Output() change: EventEmitter<boolean> = new EventEmitter();

    constructor(private http: HttpClient) { }

    getData(urlParam: string) {
        return this.http.get(`${this.url}` + urlParam);
    }

    getMenu(lang: string) {
        return this.http.get(`${this.url}` + 'menu/'+ lang);
    }

    sendEmail(data) {
        const httpOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };
        return this.http.post(`${this.url}` + 'email', data, httpOptions);
    }
    sendSubject(data){
      const httpOptions = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return this.http.post(`${this.url}` + 'post_subject', data);
    }
    editSubject(data){
      const httpOptions = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return this.http.post(`${this.url}` + 'edit_subject', data);
    }
    loginSubject(data){
      const httpOptions = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return this.http.post(`${this.url}` + 'login_subject', data);
    }
    getBisness(id){
      const httpOptions = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    };
    return this.http.post(`${this.url}` + 'get_subject', id);
    }
    offerContact(data) {
        const httpOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };
        return this.http.post(`${this.url}` + 'offerContact', data, httpOptions);
    }

    calculatorEmail(data){
        const httpOptions = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };
        //console.log(data);
        return this.http.post(`${this.url}` + 'calculatorEmail', data, httpOptions);
    }

    // abonohu(data) {
    //     const httpOptions = {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //         }
    //     };
    //     return this.http.post(`${this.url}` + 'subscribe', JSON.stringify(data), httpOptions);
    // }

    abonohu (values:any) {
        const url = `${this.url}subscribe`;
        const headers = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let body = JSON.stringify(values);
        //console.log(values);
        return this.http.post(url,body, { headers });
      }


    getSingleData(urlParam: string) {
        return this.http.get(`${this.url}` + urlParam);
    }

    toggle() {
        this.isOpen = !this.isOpen;
        this.change.emit(this.isOpen);
    }


    switch_language(){

    }

}
