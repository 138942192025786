import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Swiper, Pagination, Autoplay, EffectFlip, EffectFade } from 'swiper/js/swiper.esm.js';
import * as fbsi from '@fortawesome/free-brands-svg-icons';
import * as fssi from '@fortawesome/free-solid-svg-icons';
import { AppService } from './services/app.service';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';


// tslint:disable-next-line: max-line-length
Swiper.use([Pagination, Autoplay, EffectFlip, EffectFade]); // Swiper, Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy, Controller, A11y, History, HashNavigation, Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow

// Google Analytics
// https://medium.com/madhash/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e
declare let gtag: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']

})
export class AppComponent implements AfterViewInit, OnInit {
    faFacebookF = fbsi.faFacebookF;
    faTwitter = fbsi.faTwitter;
    faLinkedinIn = fbsi.faLinkedin;

    anchorsSwiper: Swiper;
    Bars = fssi.faBars;
    InfoCircle = fssi.faInfoCircle;
    ArrowRight = fssi.faArrowRight;
    swipernews: Swiper;
    notifications: any;
    menu: any;
    options: any = [];
    isOpen: boolean;
    currentYear: number;
    lang: string;

    constructor(
        private appService: AppService,
        private meta: Meta,
        public translate: TranslateService,
        public router: Router
    ) {
        // translate.addLangs(['sq', 'en']);
        this.lang = sessionStorage.getItem('lang');
        translate.setDefaultLang(this.lang);
        this.isOpen = true;
        this.currentYear = new Date().getFullYear();

        this.router.events.subscribe({
            next: (event) => {
                if (event instanceof NavigationEnd) {
                    gtag('config', 'UA-18345713-2', {
                        page_path: event.urlAfterRedirects
                    });
                }
            }
        });
    }

    ngOnInit() {
        this.lang = sessionStorage.getItem('lang');
        /* this.meta.addTags([
            { name: 'title', content: 'dev.al - Image &amp; Communications Development' },
            { name: 'description', content: 'Software, Web Solutions, Mobile, Marketing and Management' },
            { name: 'author', content: 'dev.al' },
            { name: 'keywords', content: 'dev, dev.al' },

            { name: 'og:url', content: 'http://dev.al/' },
            { name: 'og:type', content: 'website' },
            { name: 'og:title', content: 'dev.al - Image &amp; Communications Development' },
            { name: 'og:description', content: 'Software, Web Solutions, Mobile, Marketing and Management' },
            { name: 'og:image', content: 'http://dev.al/template/img/deval.png' },
        ]); */

        this.appService.getData('options').subscribe((data) => {
            this.options = data;
            //console.log(this.options);
        });

        this.appService.change.subscribe(isOpen => {
            this.isOpen = isOpen;
        });
        this.appService.getMenu(this.lang).subscribe((data) => {
            this.menu = data;
        });
        this.appService.getData('get_notifications/' + this.lang + '/').subscribe((data) => {
            this.notifications = data;
        });
    }

    ngAfterViewInit() { }

    toggle() {
        this.appService.toggle();
    }

    switch_language(language: string) {
        this.translate.use(language);
        sessionStorage.setItem('lang', language);
        window.location.reload();
    }

}
